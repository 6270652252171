import { getType } from "typesafe-actions";
import { IStore } from "../../store";
import { formSelectors } from "../../_core/form";
import { jobSelectors } from "../../_core/job";
import { IVessel } from "../vessel/types";
import { ILog, ILogStore } from "./@types";
import logActions from "./actions";

const logSelectors = {
	_getStore(logStore: IStore) {
		return logStore.app.logs;
	},
	getReports(logStore: ILogStore) {
		return logStore.reports;
	},
	getSelectedReport(logStore: ILogStore) {
		return logStore.selectedReport;
	},
	getLogs(logStore: ILogStore) {
		return logStore.logs;
	},
	getSelectedLog(logStore: ILogStore) {
		return logStore.selectedLog;
	},
	isLogSelected(logStore: ILogStore) {
		return !!logStore.selectedLog;
	},
	getLogIdsByDate() {
		return {};
	},
	getLogIdsByVesselId() {
		return {};
	},
	getLogIdsBySubmitted() {
		return {};
	},
	getLogEnhanced(log: ILog, vessel: IVessel) {
		return {
			...log,
			vessel,
		};
	},
	jobToLoadReports(logStore: ILogStore) {
		console.log(getType(logActions.commands.reportsLoad));
		return jobSelectors.getJobStatus(
			logStore,
			getType(logActions.commands.reportsLoad)
		);
	},
	jobToLoadSelectedReport(logStore: ILogStore) {
		return jobSelectors.getJobStatus(
			logStore,
			getType(logActions.commands.singleReportLoad)
		);
	},
	jobToLoadReportPrintTemplates(logStore: ILogStore) {
		return jobSelectors.getJobStatus(
			logStore,
			getType(logActions.commands.reportPrintTemplateLoad)
		);
	},
	jobToLoadLogs(logStore: ILogStore) {
		return jobSelectors.getJobStatus(
			logStore,
			getType(logActions.commands.logListLoad)
		);
	},
	jobToLoadSelectedLog(logStore: ILogStore) {
		return jobSelectors.getJobStatus(
			logStore,
			getType(logActions.commands.logLoad)
		);
	},
	jobToFilterLogs(logStore: ILogStore) {
		return jobSelectors.getJobStatus(
			logStore,
			getType(logActions.commands.logListFilter)
		);
	},
	jobToExportPDF() {
		return {};
	},
	jobToLoadPrintTemplates(logStore: ILogStore) {
		return jobSelectors.getJobStatus(
			logStore,
			getType(logActions.commands.logPrintTemplateLoad)
		);
	},
	// jobToLoadTotalLogCount(logStore: ILogStore) {
	// 	return jobSelectors.getJobStatus(
	// 		logStore,
	// 		getType(logActions.commands.logListTotalCountLoad)
	// 	);
	// },
	formForFilter(logStore: ILogStore) {
		return formSelectors(logStore._forms.FILTER);
	},

	// getTotalItems(logStore: ILogStore) {
	// 	return logStore._pagination.totalItems;
	// },
	getReportsCurrentPage(logStore: ILogStore) {
		return logStore.reportsPagination.currentPage;
	},
	getReportsIdsOfPage(logStore: ILogStore, pageNumber: number) {
		return logStore.reportsPagination.pageWiseListIds[pageNumber] || [];
	},
	getReportsIdsOfAllPages(logStore: ILogStore) {
		return Object.keys(logStore.reportsPagination.pageWiseListIds).reduce(
			(result: string[], pageNumber) => {
				return [
					...result,
					...logStore.reportsPagination.pageWiseListIds[parseInt(pageNumber, 0)],
				];
			},
			[]
		);
	},
	getReportsIdsOfPages(
		logStore: ILogStore,
		fromPageNumber: number,
		toPageNumber: number
	) {
		return Object.keys(logStore.reportsPagination.pageWiseListIds)
			.slice(fromPageNumber, toPageNumber)
			.reduce((result: string[], pageNumber) => {
				return [
					...result,
					...logStore.reportsPagination.pageWiseListIds[parseInt(pageNumber, 0)],
				];
			}, []);
	},
	getReportsAllPages(logStore: ILogStore) {
		return logStore.reportsPagination.pageWiseListIds;
	},
	getReportsPagination(logStore: ILogStore, pageIds?: string[]) {
		const paginationStore = logStore.reportsPagination;
		return {
			totalItems: paginationStore.totalItems || 0,
			currentPage: paginationStore.currentPage,
			itemsPerPage: paginationStore.itemsPerPage,
			itemIds: pageIds
				? pageIds
				: logStore.reportsPagination.pageWiseListIds[paginationStore.currentPage],
		};
	},
	getCurrentPage(logStore: ILogStore) {
		return logStore._pagination.currentPage;
	},
	getIdsOfPage(logStore: ILogStore, pageNumber: number) {
		return logStore._pagination.pageWiseListIds[pageNumber] || [];
	},
	getIdsOfAllPages(logStore: ILogStore) {
		return Object.keys(logStore._pagination.pageWiseListIds).reduce(
			(result: string[], pageNumber) => {
				return [
					...result,
					...logStore._pagination.pageWiseListIds[parseInt(pageNumber, 0)],
				];
			},
			[]
		);
	},
	getIdsOfPages(
		logStore: ILogStore,
		fromPageNumber: number,
		toPageNumber: number
	) {
		return Object.keys(logStore._pagination.pageWiseListIds)
			.slice(fromPageNumber, toPageNumber)
			.reduce((result: string[], pageNumber) => {
				return [
					...result,
					...logStore._pagination.pageWiseListIds[parseInt(pageNumber, 0)],
				];
			}, []);
	},
	getAllPages(logStore: ILogStore) {
		return logStore._pagination.pageWiseListIds;
	},
	getPagination(logStore: ILogStore, pageIds?: string[]) {
		const paginationStore = logStore._pagination;
		return {
			totalItems: paginationStore.totalItems || 0,
			currentPage: paginationStore.currentPage,
			itemsPerPage: paginationStore.itemsPerPage,
			itemIds: pageIds
				? pageIds
				: logStore._pagination.pageWiseListIds[paginationStore.currentPage],
		};
	},
};

export default logSelectors;
