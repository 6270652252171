import classNames from "classnames";

import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import Row from "./Row";
import { vesselUtils } from "redux/app/vessel";
import SVGAlert from "components/_common/icons/SVGAlert";
import { generateHeaderRow, getStatusValueForAlertsObject } from "./utils";
import { EDashboardAlertsStatus } from "redux/app/vessel/onboard/types";
import { vesselOnboardSelectors } from "redux/app/vessel/onboard";
import TableLoader from "./TableLoader";
import { getPaddedDate } from "redux/app/vessel/onboard/utils";
import { authSelectors } from "redux/app/auth";
const { getColorMapper } = vesselUtils;

type OwnProps = {
	tenantId?: string;
	uiFromDate?: string; // this is the date modified from UI arrow clicks
	uiToDate?: string;
};
type AlertsProps = PropsFromRedux & OwnProps;
function Alerts(props: AlertsProps) {
	const {
		alerts,
		fromDate,
		toDate,
		jobToLoadUsageAndAlerts,
		tenantId,
		uiFromDate,
		uiToDate,
		tenantCode,
	} = props;
	const alertsStatuses = [
		{
			title: "All Good",
			status: EDashboardAlertsStatus.ALL_GOOD,
			color: "#FBFFFA",
		},
		{
			title: "Medium",
			status: EDashboardAlertsStatus.MEDIUM,
			color: "#f1c40f",
		},
		{
			title: "High",
			status: EDashboardAlertsStatus.HIGH,
			color: "#e67e22",
		},
	];

	const colorMapper = getColorMapper(alertsStatuses);
	const generatedDates = generateHeaderRow(
		uiFromDate ?? fromDate,
		uiToDate ?? toDate
	);
	const augmentHeaderRow = generatedDates.map((date, index) => {
		return {
			[`col${index + 1}`]: getPaddedDate(date),
		};
	});

	const headerRow = [{ col0: "" }, ...augmentHeaderRow];

	let alertsRowOrder = [
		{ key: "engine", title: "Engine log" },
		{ key: "deck", title: "Deck log" },
		{ key: "gmdss", title: "GMDSS log" },
		{ key: "compass", title: "Compass log" },
		{ key: "port", title: "Port log" },
		// { key: "radar", title: "Radar log" },
		{ key: "orb1", title: "ORB P1" },
		{ key: "orb2", title: "ORB P2" },
		{ key: "garbage", title: "Garbage" },
		{ key: "grb2", title: "GRB P2" },
		{ key: "ballast", title: "Ballast" },
		{ key: "annex6", title: "Annex VI" },
	];

	// TODO: Remove this hardcode logic for Teekay.
	if (tenantCode === "TEEKAY") {
		alertsRowOrder = [
			{ key: "engine", title: "Engine log" },
			{ key: "gmdss", title: "GMDSS log" },
			{ key: "compass", title: "Compass log" },
			{ key: "orb1", title: "ORB P1" },
			{ key: "orb2", title: "ORB P2" },
			{ key: "garbage", title: "Garbage" },
			// { key: "grb2", title: "GRB P2" },
			{ key: "ballast", title: "Ballast" },
			{ key: "annex6", title: "Annex VI" },
		];
	}

	const bodyRows = [] as { [key: string]: any }[];

	alertsRowOrder.forEach((row) => {
		const { key: rowKey, title } = row;

		if (alerts) {
			const obj = rowKey in alerts ? (alerts as any)[rowKey] : {};
			const generatedDatesWithoutTimezone = generatedDates.map(
				(date) => date?.split("T")[0] ?? ""
			);
			const otherColumnsForRow = getStatusValueForAlertsObject(
				obj,
				generatedDatesWithoutTimezone
			);
			bodyRows.push({
				col0: { value: title },
				...otherColumnsForRow,
			});
		}
	});

	return (
		<div className="border border-[#B3B3B3] rounded-md">
			<div className="flex justify-center items-center py-2 border-b border-[#B3B3B3]">
				<SVGAlert />
				<h4 className="ml-2 font-semibold font-redhat-text">Alerts</h4>
			</div>
			<div className="border-b border-[#B3B3B3] py-2 pt-2.5">
				<div className="flex justify-center space-x-4">
					{alertsStatuses.map((statusObj) => {
						return (
							<div
								key={statusObj.title}
								className="flex flex-col items-center"
							>
								<div
									style={{ background: statusObj.color }}
									className="border px-5 py-2  border-[#D0D0D0]"
								></div>
								<p className="text-[#444444] text-[12px]">
									{statusObj.title}
								</p>
							</div>
						);
					})}
				</div>
			</div>
			<div className="px-2 py-2 overflow-x-auto">
				<table className="w-full h-full overflow-auto bg-white border-collapse rounded-md table-fixed font-redhat-text">
					<thead>
						<tr>
							{headerRow.map((header, index) => (
								<th
									key={index}
									className={classNames(
										"px-0.5 py-2 text-xs md:text-[12px] bg-white  font-medium leading-4 tracking-wide text-center text-[#444444]",
										{
											"w-28 md:w-44": !index,
										}
									)}
								>
									{/* @ts-ignore */}
									{header[`col${index}`]}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{jobToLoadUsageAndAlerts.isLoading ? (
							<TableLoader />
						) : (
							<>
								{bodyRows.map((row, index) => {
									return (
										<Row
											key={index}
											row={row}
											colorMapper={colorMapper}
										/>
									);
								})}
							</>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = store.app.vesselStore;
	const dashboardUsageAndAlerts = vesselStore.onboard.dashboardUsageAndAlerts;
	const onboardStore = vesselOnboardSelectors._getStore(store);
	const jobToLoadUsageAndAlerts =
		vesselOnboardSelectors.getJobToLoadUsageAndAlertsData(onboardStore);
	const tenantCode = authSelectors(store.app.auth).getTenantCode();

	return {
		alerts:
			vesselStore.onboard.dashboardUsageAndAlerts?.alerts ?? undefined,
		toDate: dashboardUsageAndAlerts?.toDate,
		fromDate: dashboardUsageAndAlerts?.fromDate,
		jobToLoadUsageAndAlerts,
		tenantCode,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Alerts);

/**
 * Sample data
 const bodyRows = [
		{
			col0: {value: "Engine log"},
			col1: {value:"all-good",extraInfo: {}},
			col2: "all-good",
			col3: "all-good",
			col4: "all-good",
			col5: "high",
			col6: "all-good",
			col7: "high",
		},
	];
 */
