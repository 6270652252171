import { createAction, getType } from "typesafe-actions";
import { formActions } from "../../_core/form";
import { jobActions } from "../../_core/job";
import {
	ILoadDayLogsParams,
	ILog,
	ILogFilters,
	ILogType,
	IReport,
	IReportsFilters,
	ITabLog,
	ITabReport,
} from "./@types";
import logConstants from "./constants";

const LOG_LIST_LOAD = "@log/list/LOAD"; // COMMAND ACTION
const LOG_LIST_FILTER = "@log/list/FILTER"; // COMMAND ACTION
const LOG_LOAD = "@log/LOAD"; // COMMAND ACTION
const LOG_PRINT_TEMPLATE_LOAD = "@log/selectedLog/printTemplate/LOAD"; // COMMAND ACTION
//const LOG_LIST_TOTAL_COUNT_LOAD = "@log/list/LOAD-TOTAL-COUNT"; // COMMAND ACTION
const DAY_LOG_LOAD = "@log/day-log/LOAD"; //COMMAND ACTION

const LOG_LIST_RESET = "@log/list/RESET"; // DOCUMENT ACTION
const LOG_LIST_SET = "@log/list/SET"; // DOCUMENT ACTION
const LOG_LIST_EDIT = "@log/list/EDIT"; // DOCUMENT ACTION
const LOG_SELECT = "@log/SELECT"; // DOCUMENT ACTION
const CLEAR_SELECTED_LOG = "@log/SELECT/CLEAR"; // DOCUMENT ACTION
const LOG_PRINT_TEMPLATE_SET = "@log/template/SET"; // DOCUMENT ACTION
const LOG_PRINT_TEMPLATE_RESET = "@log/template/RESET"; // DOCUMENT ACTION
const LOG_TYPE_RESET = "@log/logType/RESET"; //DOCUMENT ACTION

const PAGINATION_TOTAL_ITEMS_SET = "@pagination/total-items/SET"; // DOCUMENT
const PAGINATION_ITEMS_PER_PAGE_SET = "@pagination/items-per-page/SET"; // DOCUMENT
const PAGINATION_CURRENT_PAGE_SET = "@pagination/current-page/SET"; // DOCUMENT
const PAGINATION_PAGE_IDS_SET = "@pagination/page-wise-ids/SET"; // DOCUMENT
const PAGINATION_PAGE_ID_RESET = "@pagination/page-wise-ids/RESET"; // DOCUMENT
const PAGINATION_PAGE_IDS_RESET = "@pagination/page-wise-ids/RESET-ALL"; // DOCUMENT
const PAGINATION_RESET = "@pagination/RESET"; // DOCUMENT
const LOG_SET_ASSOCIATED_DAY_LOG = "@log/associatedDayLog/SET" // DOCUMENT

const REPORTS_COUNT_LOAD = "@reports/count/LOAD"; // COMMAND ACTION
const REPORTS_LOAD = "@reports/listing/LOAD"; // COMMAND ACTION
const REPORTS_SET = "@reports/listing/SET"; // COMMAND ACTION
const REPORTS_FILTER = "@reports/listing/FILTER"; // COMMAND ACTION

const SINGLE_REPORTS_LOAD = "@reports/single/LOAD"; // COMMAND ACTION
const REPORTS_PRINT_TEMPLATE_RESET = "@reports/print-template-reset"; // COMMAND ACTION
const SELECT_REPORTS = "@reports/select-reports"; // COMMAND ACTION
const REPORTS_PRINT_TEMPLATE_LOAD = "@reports/print-template-load"; // COMMAND ACTION
const REPORTS_PRINT_TEMPLATE_SET = "@reports/print-template-set"; // COMMAND ACTION
const CLEAR_SELECTED_REPORTS = "@reports/clear-selected-reports"; // COMMAND ACTION

const REPORTS_PAGINATION_TOTAL_ITEMS_SET = "@reports/pagination/total-items/SET"; // DOCUMENT
const REPORTS_PAGINATION_ITEMS_PER_PAGE_SET = "@reports/pagination/items-per-page/SET"; // DOCUMENT
const REPORTS_PAGINATION_CURRENT_PAGE_SET = "@reports/pagination/current-page/SET"; // DOCUMENT
const REPORTS_PAGINATION_PAGE_IDS_SET = "@reports/pagination/page-wise-ids/SET"; // DOCUMENT
const REPORTS_PAGINATION_PAGE_ID_RESET = "@reports/pagination/page-wise-ids/RESET"; // DOCUMENT
const REPORTS_PAGINATION_PAGE_IDS_RESET = "@reports/pagination/page-wise-ids/RESET-ALL"; // DOCUMENT
const REPORTS_PAGINATION_RESET = "@reports/pagination/RESET"; // DOCUMENT


const logActions = {
	commands: {
		reportPrintTemplateLoad: createAction(
			REPORTS_PRINT_TEMPLATE_LOAD,
			(
				vesselId: string,
				recordConfigVersion?: string
			) => ({
				vesselId,
				recordConfigVersion,
			})
		)(),
		// Report Load
		singleReportLoad: createAction(
			SINGLE_REPORTS_LOAD,
			(report: ITabReport, vesselId: string) => ({
				report,
				vesselId
			})
		)(),
		reportsCountLoad: createAction(
			REPORTS_COUNT_LOAD,
			(vesselId: string) => ({
				vesselId
			})
		)(),
		reportsLoad: createAction(
			REPORTS_LOAD,
			({
				pageNumber = 1,
				showPageNumberInNotification = false,
				vesselId
			}: {
				pageNumber: number;
				showPageNumberInNotification: boolean;
				vesselId: string;
			}) => ({
				pageNumber,
				showPageNumberInNotification,
				vesselId
			})
		)(),

		logListLoad: createAction(
			LOG_LIST_LOAD,
			({
				pageNumber = 1,
				showPageNumberInNotification = false,
				vesselId
			}: {
				pageNumber: number;
				showPageNumberInNotification: boolean;
				vesselId: string;
			}) => ({
				pageNumber,
				showPageNumberInNotification,
				vesselId
			})
		)(),
		logListFilter: createAction(
			LOG_LIST_FILTER,
			(filters: ILogFilters) => ({
				filters,
			})
		)(),
		logLoad: createAction(LOG_LOAD, (log: ITabLog) => ({
			log,
		}))(),
		dayLogLoad: createAction(DAY_LOG_LOAD,(dayLogParams: ILoadDayLogsParams) => ({
			dayLogParams
		}))(),
		logPrintTemplateLoad: createAction(
			LOG_PRINT_TEMPLATE_LOAD,
			(
				logType: ILogType | "Deck Log v2" | "permit",
				vesselId: string,
				code?: string
			) => ({
				logType,
				vesselId,
				code,
			})
		)(),
		//logListTotalCountLoad: createAction(LOG_LIST_TOTAL_COUNT_LOAD)(),
	},
	document: {
		clearSelectedReport: createAction(CLEAR_SELECTED_REPORTS, () => ({
			log: null,
		}))(),
		reportPrintTemplateSet: createAction(
			REPORTS_PRINT_TEMPLATE_SET,
			(printTemplate: any) => ({ printTemplate })
		)(),
		selectReport: createAction(SELECT_REPORTS, (report: IReport) => ({
			report,
		}))(),
		reportPrintTemplateReset: createAction(
			REPORTS_PRINT_TEMPLATE_RESET,
			() => ({})
		)(),
		reportsSet: createAction(REPORTS_SET, (reports: ITabReport[]) => ({
			reports,
		}))(),
		reportsFilter: createAction(
			REPORTS_FILTER,
			(filters: IReportsFilters) => ({
				filters,
			})
		)(),

		// Pagination
		reportsPaginationTotalItemsSet: createAction(
			REPORTS_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		reportsPaginationItemsPerPageSet: createAction(
			REPORTS_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		reportsPaginationCurrentPageSet: createAction(
			REPORTS_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		reportsPaginationPageIdsSet: createAction(
			REPORTS_PAGINATION_PAGE_IDS_SET,
			({
				pageNumber,
				itemIds,
			}: {
				pageNumber: number;
				itemIds: string[];
			}) => ({
				pageNumber,
				itemIds,
			})
		)(),
		reportsPaginationPageIdsResetAll: createAction(REPORTS_PAGINATION_PAGE_IDS_RESET)(),
		reportsPaginationPageIdReset: createAction(
			REPORTS_PAGINATION_PAGE_ID_RESET,
			(pageNumber: number) => ({ pageNumber })
		)(),
		reportsPaginationReset: createAction(REPORTS_PAGINATION_RESET)(),

		logTypeReset: createAction(LOG_TYPE_RESET,() => ({}))(),
		logListReset: createAction(LOG_LIST_RESET, () => ({}))(),
		logListSet: createAction(LOG_LIST_SET, (logs: ITabLog[]) => ({
			logs,
		}))(),
		logListEdit: createAction(
			LOG_LIST_EDIT,
			(logId: string, log: ILog) => ({
				logId,
				log,
			})
		)(),
		setAssociatedDayLog: createAction(
            LOG_SET_ASSOCIATED_DAY_LOG,
            (associatedDayLog: any) => ({
                associatedDayLog,
            })
        )(),
		logSelect: createAction(LOG_SELECT, (log: ILog | ILog[]) => ({
			log,
		}))(),
		clearSelectedLog: createAction(CLEAR_SELECTED_LOG, () => ({
			log: null,
		}))(),
		logPrintTemplateSet: createAction(
			LOG_PRINT_TEMPLATE_SET,
			(printTemplate: any) => ({ printTemplate })
		)(),
		logPrintTemplateReset: createAction(
			LOG_PRINT_TEMPLATE_RESET,
			() => ({})
		)(),

		paginationTotalItemsSet: createAction(
			PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		paginationItemsPerPageSet: createAction(
			PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		paginationCurrentPageSet: createAction(
			PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		paginationPageIdsSet: createAction(
			PAGINATION_PAGE_IDS_SET,
			({
				pageNumber,
				itemIds,
			}: {
				pageNumber: number;
				itemIds: string[];
			}) => ({
				pageNumber,
				itemIds,
			})
		)(),
		paginationPageIdsResetAll: createAction(PAGINATION_PAGE_IDS_RESET)(),
		paginationPageIdReset: createAction(
			PAGINATION_PAGE_ID_RESET,
			(pageNumber: number) => ({ pageNumber })
		)(),
		paginationReset: createAction(PAGINATION_RESET)(),
	},
	_forms: {
		filter: formActions<ILogFilters>(
			logConstants.forms.FILTER,
			logConstants.FEATURE
		),
	},
};
export const logJobActions = {
	logListLoad: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.logListLoad)
	),
	logListFilter: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.logListFilter)
	),
	logLoad: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.logLoad)
	),
	logPrintTemplateLoad: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.logPrintTemplateLoad)
	),
	loadDayLogs: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.dayLogLoad)
	),
	reportsCountLoad: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.reportsCountLoad)
	),
	reportsLoad: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.reportsLoad)
	),
	singleReportLoad: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.singleReportLoad)
	),
	reportPrintTemplateLoad: jobActions(
		logConstants.FEATURE,
		getType(logActions.commands.reportPrintTemplateLoad)
	),
};

export default logActions;
