import moment from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { ITabReport } from "redux/app/logs/@types";
import { IStore } from "redux/store";

interface IReportRowProps {
	report: ITabReport;
	onClick(report: ITabReport): void;
	indexNumber: number;
}

export const getTitle = (report: ITabReport) => {
	return "Signal Report";
};

export const getSelectedDate = (report: ITabReport) => {
	if (report?.selectedDate?.includes("T")) {
		if (report?.intlDateLineRecord) {
			return `${report?.selectedDate?.split("T")?.[0]} (IDL Repeat)`;
		}
		return `${report?.selectedDate?.split("T")?.[0]}`;
	}

	return "";
};

export const getStatus = (report: ITabReport) => {
	if (report?.doc_status) {
		return `${report?.doc_status?.charAt(0)}${report?.doc_status
			?.slice(1)
			.toLowerCase()}`;
	}
	return "";
};

type ReportRowProps = PropsFromRedux & IReportRowProps;
function ReportRow(props: ReportRowProps) {
	const { report } = props;

	return (
		<tr key={report._id}>
			<td className="text-gray-400">{props.indexNumber}</td>
			<td className="py-1 font-medium text-gray-800">
				{getTitle(report)}
			</td>
			<td
				className="font-mono text-center text-gray-700"
				title={moment(report.createdAt).format("DD-MM-YYYY hh:mm a")}
			>
				{moment(report.createdAt).utc().format("DD-MM-YYYY hh:mm a")}
			</td>
			<td className="font-mono text-center">{getSelectedDate(report)}</td>
			<td
				className="font-mono text-center"
				title={report?.timeline
					?.map(
						(item, index) =>
							`${item.report_type} at ${item.from_local_date} ${item.from_local_time}`
					)
					.toString()}
			>
				{report?.timeline
					?.map((item, index) => `${item?.report_type || ""}`)
					.toString()}
			</td>
			<td className="py-1 font-medium text-center text-gray-700">
				{getStatus(report)}{" "}
			</td>
			<td
				className="text-center"
				title={`${
					report?.signalApiLastProcessedDate
						? `Last Processed at ${moment(
								report?.signalApiLastProcessedDate
						  ).format("DD-MM-YYYY hh:mm a")}`
						: ""
				}`}
			>
				{report?.signalApiStatus === "SUCCESS"
					? "✅"
					: report?.signalApiStatus === "ERROR"
					? "❌"
					: "⏳"}
			</td>
			<td
				className="text-center"
				title={`${
					report?.imosApiLastProcessedDate
						? `Last Processed at ${moment(
								report?.imosApiLastProcessedDate
						  ).format("DD-MM-YYYY hh:mm a")}`
						: ""
				}`}
			>
				{report?.imosApiStatus === "SUCCESS"
					? "✅"
					: report?.imosApiStatus === "ERROR"
					? "❌"
					: "⏳"}
			</td>
			<td className="text-center">
				<button
					className="p-5 py-1 font-medium text-blue-500 underline bg-transparent border-0 shadow-none ws-input hover:text-blue-700"
					onClick={function onClick() {
						props.onClick(report);
					}}
				>
					View
				</button>
			</td>
		</tr>
	);
}

function mapStateToProps(store: IStore, ownProps: IReportRowProps) {
	return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ReportRow);
