import { IListObjectStore, IAction } from "./type";
import { Action } from "redux";
import moment from "moment";
import {
	addDays,
	addMonths,
	endOfDay,
	endOfMonth,
	endOfWeek,
	startOfDay,
	startOfMonth,
	startOfWeek,
} from "date-fns";
import { Range, StaticRange } from "react-date-range";

export const DEV_EMAIL = "wayship.develop@wayship.io";

const arrayToObject = <T extends { id: string; _id?: string }>(list: T[]) => {
	let objectFormat: IListObjectStore<T> = {
		byIds: {},
		ids: [],
	};
	list.map(
		(item) =>
			(objectFormat = {
				...objectFormat,
				byIds: {
					...objectFormat.byIds,
					[item._id || item.id]: item,
				},
				ids: [...objectFormat.ids, item._id || item.id],
			})
	);
	return objectFormat;
};
const objectToArray = <T extends { id: string }>(object: IListObjectStore<T>) =>
	object.ids.map((id) => object.byIds[id]);

function getItemById<T extends { id: string; _id?: string }>(
	itemList: T[],
	itemId: string
) {
	const allArrayObjects = arrayToObject(itemList);
	return allArrayObjects.byIds[itemId];
}

function getFeatureAction(feature: string, action: IAction<any>): IAction<any> {
	return {
		type: `${feature} [${action.type}]`,
		payload: action.payload,
		meta: {
			...action.meta,
			trueType: action.type,
			feature,
		},
	};
}

function capitalize(stringText: any) {
	if (!stringText) return stringText;
	stringText = stringText.split(" ");
	for (let i = 0, x = stringText.length; i < x; i++) {
		stringText[i] =
			stringText[i][0].toUpperCase() +
			(stringText[i] as string).toLowerCase().substr(1);
	}

	return stringText.join(" ");
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce<Params extends any[]>(
	func: (...args: Params) => any,
	timeout: number
): (...args: Params) => void {
	let timer: NodeJS.Timeout;
	return (...args: Params) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func(...args);
		}, timeout);
	};
}

function emptyDeep(
	deepObject: any,
	emptyValues = [undefined, null, ""]
): boolean {
	if (typeof deepObject === "object" && !Array.isArray(deepObject)) {
		const allValues = Object.values(deepObject);
		return allValues.reduce<boolean>(
			(result, currentValue: any, position) => {
				if (result === true) return true;
				return emptyDeep(currentValue, emptyValues);
			},
			false
		);
	}
	return emptyValues.reduce<boolean>((result, currentEmptyValue) => {
		if (result === true) return true;
		if (
			Array.isArray(currentEmptyValue) &&
			currentEmptyValue.length === 0 &&
			Array.isArray(deepObject) &&
			deepObject.length === 0
		)
			return true;
		if ((currentEmptyValue as any) === deepObject) return true;
		return result;
	}, false);
}

function printElement(idofElementToPrint: string) {
	const printContents = document.getElementById("log-book")?.innerHTML;
	const originalContents = document.body.innerHTML;
	// @ts-ignore
	document.body.innerHTML = printContents;
	window.print();
	// @ts-ignore
	document.body.innerHTML = originalContents;
	window.location.reload();
}

function substract(firstNumber: number, secondNumber: number) {
	const firstNumberString = firstNumber.toString().split(".");
	let firstNumber_max = 0;
	if (firstNumberString.length == 2) {
		firstNumber_max = firstNumberString[1].length;
	}

	const secondNumberString = secondNumber.toString().split(".");
	let secondNumber_max = 0;
	if (secondNumberString.length == 2) {
		secondNumber_max = secondNumberString[1].length;
	}

	const maxLength =
		firstNumber_max > secondNumber_max ? firstNumber_max : secondNumber_max;

	return Number((firstNumber - secondNumber).toFixed(maxLength));
}

function addUp(firstNumber: number, secondNumber: number) {
	const firstNumberString = firstNumber.toString().split(".");
	let firstNumber_max = 0;
	if (firstNumberString.length == 2) {
		firstNumber_max = firstNumberString[1].length;
	}

	const secondNumberString = secondNumber.toString().split(".");
	let secondNumber_max = 0;
	if (secondNumberString.length == 2) {
		secondNumber_max = secondNumberString[1].length;
	}

	const maxLength =
		firstNumber_max > secondNumber_max ? firstNumber_max : secondNumber_max;

	return Number((firstNumber + secondNumber).toFixed(maxLength));
}

function getItemOrEmptyPlaceholder<T>(
	placeholder: string,
	item: undefined | null | "" | 0 | T
) {
	if (item === undefined || item === null || item === "" || item === 0)
		return placeholder;
	return item;
}

function getAPIBaseUrl() {
	const isDev = process.env.NODE_ENV !== "production";
	const apiBaseUrl = isDev
		? "http://localhost:3000"
		: "https://console.wayship.io";
	return apiBaseUrl;
}

function toTitleCase(str: string) {
	return str
		.toLowerCase()
		.split(" ")
		.map(function (word) {
			return word.charAt(0).toUpperCase() + word.slice(1);
		})
		.join(" ");
}

function getDatabaseUrl() {
	let dbUrl = process.env.REACT_APP_DB_URL;
	if (process.env.NODE_ENV === "production") {
		try {
			const localstorageDBUrl = localStorage.getItem("dbUrl");
			if (localstorageDBUrl) {
				dbUrl = localstorageDBUrl;
				// console.log('dbUrl present in LS Mem!');
				// console.log({ localstorageDBUrl });
			}
			return dbUrl;
		} catch (error) {
			console.log("Error getting DB URL from LS");
			return dbUrl;
		}
	}
	return dbUrl;
}

function getServerUrl() {
	let serverUrl = process.env.REACT_APP_DOMAIN_BASE;
	if (process.env.NODE_ENV === "production") {
		try {
			const localStorageUrl = localStorage.getItem("url");

			if (localStorageUrl) {
				serverUrl = localStorageUrl;
			}
		} catch (error) {
			console.log("error getting serve url from LS");
		}
	}
	return serverUrl;
}

function getUtcTimestamp(dateString?: Date | string, format?: string) {
	// if dateString is not provided, current date will be used
	// if format is not provided, it will be parsed by default
	// const defaultFormat = "yyyy-MM-DD"
	if (!dateString) {
		if (format) return moment().utc().format(format);
	}

	if (format) return moment.utc(dateString).format(format);

	return moment.utc(dateString).format();
}

function getUtcTime(dateString?: Date | string) {
	// this function handles provided format in a chainable way
	// example: getUtcTime().format('YYYY-MM-DD')
	// getUtcTime('2020-01-01').format('YYYY-MM-DD');
	// getUtcTime().format('YYYY-MM-DD');
	// getUtcTime()

	let Obj = {
		dateString: dateString,
		format: (format?: string) => {
			if (Obj.dateString) {
				if (format) return moment.utc(Obj.dateString).format(format);
				return moment.utc(Obj.dateString).format();
			} else {
				if (format) return moment().utc().format(format);
				return moment().utc().format();
			}
		},
	};
	return Obj;
}

function padNumber(d: number) {
	return d < 10 ? "0" + d.toString() : d.toString();
}
function convertToUtcTimestamp(
	dateString: string,
	timeString: string,
	utcOffsetString: string
) {
	const dateTimeString = `${dateString}T${timeString}:00${utcOffsetString}`;
	const utcTime = moment.utc(dateTimeString).format();
	// console.log({ utcTime });
	return utcTime;
}

function getSafeUrl(name: string) {
	// make an API request to upload the attachment
	const safeName = name?.replace(/[^a-zA-Z0-9-_\.]/g, "");
	return encodeURIComponent(safeName);
}

export const defineds = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: endOfWeek(new Date()),
	startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
	endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
	past30days: moment().subtract(1, "month").toDate(),
	past15days: moment().subtract(15, "days").toDate(),
};

function getVerboseFormattedDate(range: Range, staticRanges: StaticRange[]) {
	const label = staticRanges.find((staticRange) =>
		staticRange.isSelected(range)
	)?.label;
	if (label) {
		return label;
	}
	return `${moment(range.startDate, "DD MMM, YYYY").format(
		"DD MMM, YYYY"
	)} - ${moment(range.endDate, "DD MMM, YYYY").format("DD MMM, YYYY")}`;
}

function formatSystemDate(date: string) {
	// sample date 2024-08-09T07:58:05.674Z
	// format to 2024-08-09 07:58
	if (!date) return "";
	const dateParts = date.split("T");
	const timeParts = dateParts[1].split(":");
	return `${dateParts[0]} ${timeParts[0]}:${timeParts[1]} UTC`;
}

function getTimeDifference(cloudServerTime: string, localServerTime: string) {
	if (cloudServerTime && localServerTime) {
		const timeDifference = moment(localServerTime).diff(cloudServerTime);
		const difference = moment.duration(timeDifference).humanize();
		// if difference is "a few seconds" then return "No difference"
		if (difference === "a few seconds") {
			return "No difference";
		}
		return `${difference} ${timeDifference > 0 ? "ahead" : "behind"}`;
	}
	return null;
}

const commonUtils = {
	arrayToObject,
	objectToArray,
	getFeatureAction,
	capitalize,
	debounce,
	emptyDeep,
	printElement,
	getItemById,
	substract,
	addUp,
	getItemOrEmptyPlaceholder,
	toTitleCase,
	getDatabaseUrl,
	getServerUrl,
	getUtcTimestamp,
	getUtcTime,
	padNumber,
	convertToUtcTimestamp,
	getSafeUrl,
	getAPIBaseUrl,
	getVerboseFormattedDate,
	defineds,
	formatSystemDate,
	getTimeDifference,
};

export default commonUtils;
