const logConstants = {
	FEATURE: "@logs",
	forms: {
		FILTER: "FILTER",
	},
};

export const LOGS_PER_PAGE = 10;
export const REPORTS_PER_PAGE = 10;

export default logConstants;
